import { format, parse} from 'date-fns';
import _ from "lodash";
import moment from "moment";

const { JAVASCRIPT_DATA_TYPE } = require("./Constants");

export const isEmpty = (value) => {
  if (value === null || value === undefined || value.length === 0) return true;
  if (Array.isArray(value) || typeof value === "string") return !value.length;
  return Object.keys(value).length === 0;
};

export const isObject = (obj, canBeEmpty = false) => {
  if (obj && typeof obj === JAVASCRIPT_DATA_TYPE.OBJECT && !Array.isArray(obj)) {
    if ((!canBeEmpty && Object.keys(obj).length > 0) || canBeEmpty) {
      return true;
    }
    return false;
  }
  return false;
};

export const nonEmptyArray = (array) => {
  if (array && Array.isArray(array) && array.length > 0) {
    return true;
  }
  return false;
};

export const isBoolean = (bool) => {
  if (typeof bool === JAVASCRIPT_DATA_TYPE.BOOLEAN) {
    return true;
  }
  return false;
};

export const areObjectsEqual = (obj1, obj2) => {
  if (obj1 === obj2) return true;

  if (obj1 instanceof Date && obj2 instanceof Date) return obj1.getTime() === obj2.getTime();

  if (!obj1 || !obj2 || (typeof obj1 !== "object" && typeof obj2 !== "object"))
    return obj1 === obj2;

  if (obj1.prototype !== obj2.prototype) return false;

  const keys = Object.keys(obj1);
  if (keys.length !== Object.keys(obj2).length) return false;

  return keys.every((k) => this.areObjectsEqual(obj1[k], obj2[k]));
};

export const getQueryParams = (query) => {
  let queryParams = "";

  if (query && Object.keys(query)?.length) {
    queryParams = Object.entries(query)
      .map((e) => `${e[0]}=${encodeURIComponent(e[1])}`)
      .join(",");
  }

  return queryParams;
};

export class Utility {
  static convertInTitleCase(str) {
    if (str === undefined || str === null || typeof str === undefined || str === "") return "";
    else str = str.toString();

    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  static isEmpty = (value) => {
    return _.isEmpty(value);
  };
  static isNotEmpty = (value) => {
    return !_.isEmpty(value);
  };
  static deepClone = (obj) => {
    return _.cloneDeep(obj);
  };

  static omitBy = (obj, predicate) => {
    return _.omitBy(obj, predicate);
  };

  static defaultIfEmpty(value, defaultValue = "") {
    return this.isEmpty(value) ? defaultValue : value;
  }

  static debounce = (func, wait) => {
    return _.debounce(func, wait);
  };
  static isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (err) {
      return false;
    }
  };
  static capitalizeFirstLetter = (string) => {
    let formattedString = string.charAt(0).toUpperCase() + string.slice(1);
    formattedString = formattedString.replace(/([A-Z])/g, " $1").trim();
    return formattedString;
  };
  static convertToDate(dateString, formatString) {
    return moment(dateString).format(formatString);
  }

  static DATE_FORMATS = {
    "DD-MM-YYYY": "dd-MM-yyyy",
    "MM-DD-YYYY": "MM-dd-yyyy",
    "YYYY-MM-DD": "yyyy-MM-dd",
    "DD/MM/YYYY": "dd/MM/yyyy",
    "MM/DD/YYYY": "MM/dd/yyyy",
    "D MMM YYYY": "d MMM yyyy",
  };

  
  static getFormattedDateString(dateStr, fromFormat, toFormat) {
    console.log(dateStr, fromFormat, toFormat);

    try {
      return format(parse(dateStr, fromFormat, new Date()), toFormat);
    } catch (e) {
      console.error(e);
      return "";
    }
  }

  static getDateStrFromDate(date, toFormat) {
    console.log(`getDateStrFromDate: ${date}, ${toFormat}`);
    try {
      return format(date, toFormat);
    } catch (e) {
      console.error("Error formatting Date: ", date);
      return "";
    }
  }

  static isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
  };
}
