import {
  BRIGHTPEARL_SET_CONFIG,
  BRIGHTPEARL_SET_CUSTOMERS,
  BRIGHTPEARL_SET_ORDERS,
  BRIGHTPEARL_SET_PRODUCTS,
  BRIGHTPEARL_SET_ACCOUNTS,
  BRIGHTPEARL_SET_JOB,
  BRIGHTPEARL_SET_PAYMENT_CONFIG,
  BRIGHTPEARL_SET_PRODUCT_ATTRIBUTES,
  BRIGHTPEARL_SET_DESKERA_PRODUCTS,
  BRIGHTPEARL_SET_TAX_MAPPING,
  BRIGHTPEARL_SET_WAREHOUSE_MAPPING,
  BRIGHTPEARL_SET_INVENTORY_TRANSACTIONS,
  BRIGHTPEARL_SET_WAREHOUSE_ERRORS,
  BRIGHTPEARL_SET_MAPPED_DESKERA_WAREHOUSE,
  BRIGHTPEARL_SET_PURCHASE_ORDERS
} from "../types";

const initialState = {
  config: {
    loading: true,
    data: {},
  },
  orders: {
    loading: true,
    data: [],
  },
  accounts: {
    loading: true,
    data: [],
  },
  products: {
    loading: true,
    data: [],
  },
  customers: {
    loading: true,
    data: [],
  },
  job: {
    loading: true,
    data: {},
  },
  paymentConfig: {
    loading: true,
    data: {},
  },
  productAttributes: {
    loading: true,
    data: {},
  },
  deskeraProducts: {
    loading: true,
    data: {},
  },
  inventoryTransactions: {
    loading: true,
    data: {},
  },
  taxMapping: {
    loading: true,
    data: {},
  },
  warehouseMapping: {
    loading: true,
    data: {},
  },
  mappedDeskeraWarehouses: new Set(),
  deskeraWarehouses : [],
  errors: {
    data: {
      warehouse: [],
      deskeraWarehouse: [],
      orders: [],
      products: [],
      customers: [],
      accounts: [],
    },
  },
};

function brightPearlReducer(state = initialState, action = {}) {
  switch (action.type) {
    case BRIGHTPEARL_SET_CONFIG:
      return {
        ...state,
        config: {
          loading: false,
          data: action.config,
        },
      };
    case BRIGHTPEARL_SET_ACCOUNTS:
      return {
        ...state,
        accounts: {
          loading: false,
          data: action.accounts,
        },
      };
    case BRIGHTPEARL_SET_CUSTOMERS:
      return {
        ...state,
        customers: {
          loading: false,
          data: action.customers,
        },
      };
    case BRIGHTPEARL_SET_ORDERS:
      return {
        ...state,
        orders: {
          loading: false,
          data: action.orders,
        },
      };
    case BRIGHTPEARL_SET_PURCHASE_ORDERS:
      return {
        ...state,
        purchaseOrders: {
          loading: false,
          data: action.purchaseOrders,
        },
      };
    case BRIGHTPEARL_SET_PRODUCTS:
      return {
        ...state,
        products: {
          loading: false,
          data: action.products,
        },
      };
    case BRIGHTPEARL_SET_JOB: {
      return {
        ...state,
        job: {
          loading: false,
          job: action.job,
        },
      };
    }
    case BRIGHTPEARL_SET_PAYMENT_CONFIG:
      return {
        ...state,
        paymentConfig: {
          loading: false,
          data: action.paymentConfig,
        },
      };
    case BRIGHTPEARL_SET_PRODUCT_ATTRIBUTES:
      return {
        ...state,
        productAttributes: {
          loading: false,
          data: action.productAttributes,
        },
      };
    case BRIGHTPEARL_SET_DESKERA_PRODUCTS:
      return {
        ...state,
        deskeraProducts: {
          loading: false,
          data: action.products,
        },
      };
    case BRIGHTPEARL_SET_INVENTORY_TRANSACTIONS:
      return {
        ...state,
        inventoryTransactions: {
          loading: false,
          data: action.inventoryTransactions,
        },
      };
    case BRIGHTPEARL_SET_TAX_MAPPING:
      return {
        ...state,
        taxMapping: {
          loading: false,
          data: action.taxMapping,
        },
      };

    case BRIGHTPEARL_SET_WAREHOUSE_MAPPING:
      return {
        ...state,
        warehouseMapping: {
          loading: false,
          data: action.warehouseMapping,
        },
      };
    case BRIGHTPEARL_SET_WAREHOUSE_ERRORS:
      return {
        ...state,
        errors: {
          data: {
            ...state.errors.data,
            warehouse: action.warehouseErrors,
          },
        },
      };

    case BRIGHTPEARL_SET_MAPPED_DESKERA_WAREHOUSE:
      return {
        ...state,
        errors: {
          data: {
            ...state.errors.data,
            deskeraWarehouse: action.payload ?? [],
          },
        },
      };

    default:
      return state;
  }
}

export default brightPearlReducer;