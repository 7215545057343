import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckIcon from "src/assets/Check";
import CloseIcon from "src/assets/Close";
import DeleteLogo from "src/assets/Delete";
import Alert from "src/components/Alert";
import { CSSTransition } from "react-transition-group";
import PlusLogo from "src/assets/Plus";
import {
  connectToBrightPearl,
  getDeskeraBooksAccounts,
  getDeskeraBooksTaxes,
  getDeskeraBooksWarehouses,
  getDeskeraProductUom, getDeskeraRedirectUrl,
  getDeskeraUserInfo,
  setBrightPearlConfig,
  setDisconnectedDeskeraAccount,
  getBrightPearlWarehouseMappings
} from "src/api";
import {
  disconnectAccount,
  loadAccounts,
  loadConfig,
  loadWarehouseMappings,
  processWarehouseData,
  setConfig,
  setWarehouseMappings,
  updateWarehouseMappings,
} from "src/redux/actions/brightpearl";
import SyncDisableIcon from "src/assets/SyncDisable";
import Select from "react-select";
import FloppyDiskIcon from "src/assets/FloppyDisk";
import customSelectTheme from "src/utils/selectTheme";
import { addToast } from "src/redux/actions/toasts";
import SyncIcon from "src/assets/Sync";
import { setDeskeraInfo } from "src/redux/actions/auth";
import { Utility } from "src/utils/Utility";

const Config = () => {
  const dispatch = useDispatch();
  const brightPearlConfig = useSelector((state) => state.brightpearl.config);
  const config = useSelector((state) => state.brightpearl.config);
  const deskeraInfo = useSelector((state) => state.auth.deskeraInfo);
  const accounts = useSelector((state) => state.brightpearl.accounts);
  const warehouseMappings = useSelector((state) => state.brightpearl.warehouseMapping);
  const [validAccounts, setValidAccounts] = useState(false);
  const [disconnectWarning, setDisconnectWarning] = useState(false);
  const [disconnectButton, setDisconnectButton] = useState(true);
  const [connectButton, setConnectButton] = useState(true);
  const [configOpen, setConfigOpen] = useState(false);
  const [name, setName] = useState("");
  const [storeAccountCode, setStoreAccountCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [saveTransactionSyncLoading, setSaveTransactionSyncLoading] = useState(false);
  const [syncInventoriesDeskeraToSource, setSyncInventoriesDeskeraToSource] = useState(
    brightPearlConfig.data.syncInventoriesDeskeraToSource
  );
  const [syncInventoriesSourceToDeskera, setSyncInventoriesSourceToDeskera] = useState(
    brightPearlConfig.data.syncInventoriesSourceToDeskera
  );

  const storeErrors = useSelector((state) => state.brightpearl.errors.data);
  const hasStoreErrors = useMemo(() => {
    return Object.values(storeErrors).some((errors) => errors.length > 0);
  }, [storeErrors]);

  const [twoWaySync, setTwoWaySync] = useState(brightPearlConfig.data.twoWaySync);
  const [brightPearlErrors, setBrightPearlErrors] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [deskeraAccounts, setDeskeraAccounts] = useState([]);
  const [deskeraWarehouses, setDeskeraWarehouses] = useState([]);
  const [deskeraTaxes, setDeskeraTaxes] = useState([]);
  const [deskeraProductUom, setDeskeraProductUom] = useState([]);
  const [defaultDeskeraWarehouses, setDefaultDeskeraWarehouses] = useState([]);
  const [loadingUpdateWarehouseMapping, setLoadingUpdateWarehouseMapping] = useState(false);

  const useBooksTaxRateOptions = [
    { value: "CREATE_NEW", label: "Create New Product" },
    { value: "MAP_MANUALLY", label: "I Will Map Manually" },
  ];
  const YesOrNo = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const [error, setError] = useState("");
  const [accountsConfigured, setAccountsConfigured] = useState(false);
  const [saveConfigLoading, setSaveConfigLoading] = useState(false);
  const [saveSyncLoading, setSaveSyncLoading] = useState(false);
  const [syncWarning, setSyncWarning] = useState(false);
  const [syncButton, setSyncButton] = useState(true);
  const configAccounts = [
    {
      name: "Bank Account",
      accessor: "bankAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Purchase Account",
      accessor: "productPurchaseAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Purchase Return Account",
      accessor: "productPurchaseReturnAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Sales Account",
      accessor: "productSalesAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Sales Return Account",
      accessor: "productSalesReturnAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Payable Account",
      accessor: "contactPayableAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Receivable Account",
      accessor: "contactReceivableAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Cost of Goods Sold Account",
      accessor: "productCostOfGoodsSoldAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Inventory Account",
      accessor: "productInventoryAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Stock Adjustment Account",
      accessor: "productStockAdjustmentAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Product Unit of Measure",
      accessor: "productUom",
      options: deskeraProductUom,
    },
    {
      name: "Product Purchase Tax",
      accessor: "productPurchaseTaxCode",
      options: deskeraTaxes,
    },
    {
      name: "Product Sales Tax",
      accessor: "productSalesTaxCode",
      options: deskeraTaxes,
    },
    {
      name: "Product Warehouse",
      accessor: "productWarehouseCode",
      options: defaultDeskeraWarehouses.filter((wh) => wh.value !== "NO_SPECIFIC_WH"),
    },
    {
      name: "Product Action at Deskera",
      accessor: "productAction",
      options: useBooksTaxRateOptions,
    },
    {
      name: "Hide Sync Products",
      accessor: "hideProducts",
      options: YesOrNo,
    },
    {
      name: "Enable Sync Of Purchase Order to Brightpearl",
      accessor: "SyncPoDeskeraToBrightpearl",
      options: YesOrNo,
    }
  ];
  useEffect(() => {
    dispatch(loadConfig());
    dispatch(loadAccounts());
    getBooksAccounts();
    getBooksWarehouses();
    getBooksProductUom();
    getBooksTaxes();
    dispatch(loadWarehouseMappings());
    handleWarehouseDataProperly();
  }, []);

  async function handleWarehouseDataProperly() {
    try {
      await Promise.all([
        getBrightPearlWarehouseMappings(false),
        getDeskeraBooksWarehouses(),
      ]).then((fulfille) => {
        const [warehouseMappings, warehouses] = fulfille;

        dispatch(processWarehouseData(warehouses, warehouseMappings));
      });
    } catch (error) {
      console.error("Error handling warehouse data:", error);
    }
  }
  

  useEffect(() => {
    setTwoWaySync(brightPearlConfig.data.twoWaySync);
    setSyncInventoriesDeskeraToSource(brightPearlConfig.data.syncInventoriesDeskeraToSource);
    setSyncInventoriesSourceToDeskera(brightPearlConfig.data.syncInventoriesSourceToDeskera);
  }, [brightPearlConfig]);

  function updateWarehouseCodeAndName(data, key, id, newCode, newName) {
    if (data && data[key] && Array.isArray(data[key])) {
      for (let warehouse of data[key]) {
        if (warehouse.id === id) {
          warehouse.deskeraWarehouseCode = newCode;
          warehouse.deskeraWarehouseName = newName;
        }
      }
    }
    return data;
  }

  function brightPearlConnect() {
    return (
      <div
        className="vw-100 vh-100 d-flex justify-content-center align-items-center"
        style={{
          zIndex: 99,
          position: "absolute",
          top: 0,
          left: 0,
          background: "rgba(0, 0, 0, 0.4)",
        }}
      >
        <div className="card w-600 dk-card dk-card-shadow mb-4">
          <div className="card-body">
            <div className="form-group">
              <div className="text-muted mb-2" aria="label">
                <b>Store Name</b>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Ex. Nunes Greens"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {brightPearlErrors.name && (
                <span className="text-danger">{brightPearlErrors.name}</span>
              )}
            </div>
            <div className="form-group">
              <div className="text-muted mb-2" aria="label">
                <b>Store Account Code</b>
              </div>
              <input
                id="site"
                type="text"
                className="form-control"
                placeholder="Ex. nunesgreensstore"
                value={storeAccountCode}
                onChange={(e) => setStoreAccountCode(e.target.value)}
              />
              {brightPearlErrors.site && (
                <span className="text-danger">{brightPearlErrors.site}</span>
              )}
            </div>

            <div className="d-flex flex-row-reverse">
              <button
                className="btn border-radius-m p-v-s text-white bg-success"
                onClick={handleAddDeskeraAccount}
                disabled={loading}
              >
                <span className="svg-icon svg-baseline mr-2 svg-white">
                  {loading ? (
                    <div class="spinner-border text-white spinner-border-sm" role="status"></div>
                  ) : (
                    <PlusLogo />
                  )}
                </span>
                <span>Connect</span>
              </button>
              <button
                className="btn border-radius-m p-v-s mr-2 text-white bg-danger"
                onClick={(e) => setConfigOpen(false)}
                // style={{marginRight: "10px"}}
              >
                <span className="svg-icon svg-baseline mr-2 svg-disabled svg-white">
                  <CloseIcon />
                </span>
                <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  async function getBooksTaxes() {
    try {
      const taxes = await getDeskeraBooksTaxes();
      const taxesOptions = [];
      taxes.forEach((tax) => {
        taxesOptions.push({ value: tax.code, label: tax.name });
      });
      setDeskeraTaxes(taxesOptions);
    } catch {
      setError("Failed to load Deskera Books Taxes");
    }
  }

  async function getBooksAccounts() {
    try {
      const accounts = await getDeskeraBooksAccounts();
      const accountsOptions = [];
      accounts.forEach((account) => {
        accountsOptions.push({ value: account.code, label: account.name });
      });
      setDeskeraAccounts(accountsOptions);
    } catch {
      setError("Failed to load Deskera Books accounts");
    }
  }
  async function saveTransactionSync(e) {
    e.preventDefault();
    setSaveTransactionSyncLoading(true);
    const body = {
      twoWaySync: brightPearlConfig.data.twoWaySync ? brightPearlConfig.data.twoWaySync : false,
      syncInventoriesDeskeraToSource: brightPearlConfig.data.syncInventoriesDeskeraToSource
        ? brightPearlConfig.data.syncInventoriesDeskeraToSource
        : false,
      syncInventoriesSourceToDeskera: brightPearlConfig.data.syncInventoriesSourceToDeskera
        ? brightPearlConfig.data.syncInventoriesSourceToDeskera
        : false,
    };

    const newConfig = { ...brightPearlConfig.data };

    try {
      const resp = await setBrightPearlConfig(newConfig);
      dispatch(setConfig(resp));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Transaction sync config updated successfully.",
        })
      );
      setFormErrors([]);
    } catch {
      setError("Failed to set config");
    }
    setSaveTransactionSyncLoading(false);
  }

  async function getBooksWarehouses() {
    try {
      const warehouses = await getDeskeraBooksWarehouses();
      const warehouseOptions = [];
      warehouses.forEach((warehouse) => {
        warehouseOptions.push({ value: warehouse.code, label: warehouse.name });
      });
      setDeskeraWarehouses(warehouseOptions);
      const tempwh = warehouseOptions;
      tempwh.push({ value: "NO_SPECIFIC_WH", label: "No Specific WareHouse" });
      setDefaultDeskeraWarehouses(tempwh);
    } catch {
      setError("Failed to load Deskera Books warehouses");
    }
  }

  async function getBooksProductUom() {
    try {
      const productUom = await getDeskeraProductUom();
      const productUomOptions = [];
      productUom.forEach((uom) => {
        productUomOptions.push({ value: uom.id, label: uom.name });
      });
      setDeskeraProductUom(productUomOptions);
    } catch {
      setError("Failed to load Deskera Books products uom");
    }
  }

  function connectedModal() {
    return (
      <div className="card-body">
        <p className="text-muted">Your Deskera Account is Connected</p>
        <div className="d-flex flex-row-reverse">
          {disconnectButton && (
            // <DKButton
            //   className="bg-danger px-3 text-white"
            //   onClick={(e) => setDisconnectWarning(true)}
            //   title={"Disconnect"}
            //   style={{ marginRight: "15px" }}
            // ></DKButton>
            <button
              // style={{ marginRight: "20px" }}
              onClick={(e) => setDisconnectWarning(true)}
              className="btn border-radius-m p-v-s text-white bg-danger"
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                <DeleteLogo />
              </span>
              <span>Disconnect</span>
            </button>
          )}
          <CSSTransition
            in={disconnectWarning}
            timeout={100}
            classNames="scale-opacity"
            unmountOnExit
            onEnter={() => setDisconnectButton(false)}
            onExited={() => setDisconnectButton(true)}
          >
            <Alert className="m-0 flex-fill" type="warning">
              <p className="m-0">Disconnecting will delete data. Continue?</p>
              <div className="d-flex flex-row-reverse">
                <button
                  className="btn dk-btn font-weight-bold text-muted px-3"
                  onClick={handleDisconnectDeskeraAccount}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CheckIcon />
                  </span>
                </button>
                <button
                  className="btn dk-btn mr-2 px-3"
                  onClick={(e) => setDisconnectWarning(false)}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CloseIcon />
                  </span>
                </button>
              </div>
            </Alert>
          </CSSTransition>
        </div>
      </div>
    );
  }

  function connectModal() {
    return (
      <div className="card-body">
        <p className="text-muted">Connect your Deskera account.</p>
        <div className="d-flex flex-row-reverse">
          <button
            className="btn border-radius-m p-v-s text-white bg-success"
            onClick={handleConnectDeskeraOrg}
          >
            <span className="svg-icon svg-baseline mr-2 svg-white">
              <PlusLogo />
            </span>
            <span>Connect</span>
          </button>
        </div>
      </div>
    );
  }

  function brightPearlConnectedModal() {
    return (
      <div className="card w-600 dk-card dk-card-shadow mb-4">
        <div className="card-body">
          {accounts.data.length > 0 && (
            <table className="table m-0 table-no-padding">
              <thead>
                <tr>
                  <th>Account</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {accounts.data.map((account, index) => (
                  <tr>
                    <td className="align-middle">
                      <p className="m-0">
                        <b>{account.name}</b>
                      </p>
                      <span>
                        <small>{account.id}</small>
                      </span>
                    </td>
                    <td className="align-middle text-right" style={{ width: 180 }}>
                      <button
                        className="btn text-danger px-4 dk-btn border border-danger"
                        onClick={(e) => handleDisconnectBrightPearlAccount(e, account.id)}
                      >
                        <span className="svg-icon svg-baseline mr-2 svg-danger">
                          <DeleteLogo />
                        </span>
                        <span>Disconnect</span>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    );
  }

  function brightPearlConnectModal() {
    return (
      <div className="card w-600 mb-4 dk-card dk-card-shadow">
        <div className="card-body">
          <p className="text-muted">Link your BrightPearl accounts.</p>
          <div className="d-flex flex-row-reverse mb-2">
            <button
              className="btn border-radius-m p-v-s text-white bg-success"
              onClick={(e) => setConfigOpen(true)}
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                <PlusLogo />
              </span>
              <span>
                {accounts.data.length ? "Add Another Store" : "Connect BrightPearl Store"}
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  }

  async function handleDisconnectDeskeraAccount(e) {
    e.preventDefault();
    try {
      const disconnectResp = await setDisconnectedDeskeraAccount();
      const userInfo = await getDeskeraUserInfo();
      dispatch(setDeskeraInfo(userInfo));
    } catch {
      setError("Failed to disconnect Deskera account");
    }
  }

  async function handleAddDeskeraAccount() {
    await connectToBrightPearl(name, storeAccountCode);
  }

  async function handleConnectDeskeraOrg(e) {
    e.preventDefault();
    try {
      const deskeraConnectUrl = await getDeskeraRedirectUrl();
      window.open(deskeraConnectUrl.url, "_self");
    } catch {
      setError("Failed to fetch deskera url");
    }
  }

  async function handleDisconnectBrightPearlAccount(e, id) {
    dispatch(disconnectAccount(id));
  }
  function handleConfigLoad() {
    setAccountsConfigured(true);
    configAccounts.forEach((configAccount) => {
      if (!config.data[configAccount.accessor]) {
        setAccountsConfigured(false);
        return;
      }
    });
  }

  function checkAccountsAreValid() {
    const configErrors = {};
    if (!config.data.bankAccountCode) {
      configErrors.bankAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, bankAccountCode: "Select a valid account" });
    } //1

    if (!config.data.productPurchaseAccountCode) {
      configErrors.productPurchaseAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, productPurchaseAccountCode: "Select a valid account" });
    } //2

    if (!config.data.productSalesAccountCode) {
      configErrors.productSalesAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, productSalesAccountCode: "Select a valid account" });
    } //3

    if (!config.data.productSalesReturnAccountCode) {
      configErrors.productSalesReturnAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, productSalesReturnAccountCode: "Select a valid account" });
    } //4

    if (!config.data.productPurchaseReturnAccountCode) {
      configErrors.productPurchaseReturnAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, productPurchaseReturnAccountCode: "Select a valid account" });
    } //5

    if (!config.data.contactPayableAccountCode) {
      configErrors.contactPayableAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, contactPayableAccountCode: "Select a valid account" });
    } //6

    if (!config.data.contactPayableAccountCode) {
      configErrors.contactPayableAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, contactPayableAccountCode: "Select a valid account" });
    } //7

    if (!config.data.contactReceivableAccountCode) {
      configErrors.contactReceivableAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, contactReceivableAccountCode: "Select a valid account" });
    } //8

    if (!config.data.productUom) {
      configErrors.productUom = "Select a valid account";
      setFormErrors({ ...formErrors, productUom: "Select a valid account" });
    } //9
    if (!config.data.productWarehouseCode) {
      configErrors.productWarehouseCode = "Select a valid account";
      setFormErrors({ ...formErrors, productWarehouseCode: "Select a valid account" });
    } //10
    if (!config.data.productCostOfGoodsSoldAccountCode) {
      configErrors.productCostOfGoodsSoldAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, productCostOfGoodsSoldAccountCode: "Select a valid account" });
    } //11
    if (!config.data.productInventoryAccountCode) {
      configErrors.productInventoryAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, productInventoryAccountCode: "Select a valid account" });
    } //12
    if (!config.data.productStockAdjustmentAccountCode) {
      configErrors.productStockAdjustmentAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, productStockAdjustmentAccountCode: "Select a valid account" });
    } //13
    if (!config.data.productAction) {
      configErrors.productAction = "Select a valid action for products to create at Deskera";
      setFormErrors({
        ...formErrors,
        productAction: "Select a valid action for products to create at Deskera",
      });
    } //14

    if (Object.keys(configErrors).length > 0) {
      setFormErrors(configErrors);
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: "Some account mappings are missing.",
        })
      );
      return;
    } else {
      setFormErrors({});
      setValidAccounts(true);
    }
  }

  async function saveAccountConfigs(e) {
    e.preventDefault();
    checkAccountsAreValid();
    if (validAccounts) {
      setSaveConfigLoading(true);
      try {
        const updatedConfig = await setBrightPearlConfig(config.data);
        dispatch(setConfig(updatedConfig));
        dispatch(
          addToast({
            type: "success",
            title: "Success",
            message: "Account configurations saved successfully",
          })
        );
      } catch (e) {
        setError("Failed to save account configurations");
      } finally {
        setSaveConfigLoading(false);
      }
    }
  }

  async function handleUpdateSync(e, enabled) {
    e.preventDefault();
    setSaveSyncLoading(true);
    try {
      const updatedConfig = await setBrightPearlConfig({ ...config.data, syncEnabled: enabled });
      dispatch(setConfig(updatedConfig));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: `Sync ${enabled ? "enabled" : "disabled"} successfully`,
        })
      );
      setSyncWarning(false);
      setSyncButton(true);
    } catch (e) {
      setError("Failed to update sync");
    } finally {
      setSaveSyncLoading(false);
    }
  }

  function syncEnabledModal() {
    return (
      <div className="card-body">
        <p className="text-muted">
          Sync is enabled for your account. Orders, Products and Customers from your BrightPearl
          account will be synced with Deskera Books.
        </p>
        <div className="d-flex flex-row-reverse">
          {syncButton && (
            <button
              className="btn border-radius-m p-v-s text-white bg-danger"
              onClick={(e) => setSyncWarning(true)}
              // style={{ marginRight: "20px" }}
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                <SyncDisableIcon />
              </span>
              <span>Disable Sync</span>
            </button>
          )}
          <CSSTransition
            in={syncWarning}
            timeout={100}
            classNames="scale-opacity"
            unmountOnExit
            onEnter={() => setSyncButton(false)}
            onExited={() => setSyncButton(true)}
          >
            <Alert type="warning" className="m-0 flex-fill">
              <p className="m-0">Data will no longer be synced. Continue?</p>
              <div className="d-flex flex-row-reverse">
                <button
                  className="btn dk-btn font-weight-bold text-muted px-3"
                  onClick={(e) => handleUpdateSync(e, false)}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CheckIcon />
                  </span>
                </button>
                <button className="btn dk-btn mr-2 px-3" onClick={(e) => setSyncWarning(false)}>
                  <span className="svg-icon svg-baseline svg-warning">
                    <CloseIcon />
                  </span>
                </button>
              </div>
            </Alert>
          </CSSTransition>
        </div>
      </div>
    );
  }

  function syncDisabledModal() {
    return (
      <div className="card-body">
        <p className="text-muted">
          Sync is disabled for your account. Orders, Products and Customers from your BrightPearl
          account are not being synced with Deskera Books.
        </p>
        <div className="d-flex flex-row-reverse">
          <button
            className="btn border-radius-m p-v-s text-white bg-success"
            onClick={(e) => handleUpdateSync(e, true)}
            disabled={saveSyncLoading}
          >
            <span className="svg-icon svg-baseline mr-2 svg-white">
              <SyncIcon />
            </span>
            <span>Enable Sync</span>
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className="p-4 w-auto">
      {configOpen && brightPearlConnect()}
      <h3 className="mb-4">Account Setup</h3>
      {deskeraInfo.accountConnected && accounts.data.length > 0 && hasStoreErrors && (
        <div className="mb-4">
          <Alert
            type="warning"
            className="p-2 border border-yellow-300 bg-yellow-50 text-yellow-800"
          >
            {Object.keys(storeErrors).map((key) => {
              if (storeErrors[key].length <= 0) {
                return null;
              }

              return (
                <div key={key} className="mb-2">
                  <h6 className="font-semibold">{Utility.capitalizeFirstLetter(key)}</h6>
                  <ul className="list-disc ml-5">
                    {storeErrors[key].map((error, index) => (
                      <li key={index} className="text-sm">
                        {error}
                      </li>
                    ))}
                  </ul>
                </div>
              );
            })}
          </Alert>
        </div>
      )}
      <div className="border-bottom w-600 mb-2 text-muted">
        <h5>Deskera Account</h5>
      </div>
      <div className="card w-600 dk-card dk-card-shadow mb-4">
        {deskeraInfo.accountConnected ? connectedModal() : connectModal()}
      </div>
      {deskeraInfo.accountConnected && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>BrightPearl Stores</h5>
          </div>
          {accounts.data.length > 0 ? brightPearlConnectedModal() : brightPearlConnectModal()}
        </>
      )}

      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Books Accounts</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            <div className="card-body">
              {!accountsConfigured && (
                <Alert type="primary">
                  Accounts must be configured before BrightPearl products can be synced with Deskera
                  Books.
                </Alert>
              )}
              {configAccounts.map((configAccount) => {

                const noPlaceholder = ["SyncPoDeskeraToBrightpearl", "hideProducts"];

                return <>
                
                <div className="form-group">
                  <div className="text-muted mb-2" aria="label">
                    <b>{configAccount.name}</b>
                  </div>
                  <Select
                    placeholder={noPlaceholder.includes(configAccount.accessor) ? "Select" : "Select an account"}
                    theme={customSelectTheme}
                    options={configAccount.options}
                    isSearchable={true}
                    menuPlacement="auto"
                    onChange={(e) => {
                      const updated = { ...config.data, [configAccount.accessor]: e.value };
                      dispatch(setConfig(updated));
                    }}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    value={configAccount.options.filter(
                      (obj) => obj.value === config.data[configAccount.accessor]
                    )}
                  />
                  {formErrors[configAccount.accessor] && (
                    <div>{formErrors[configAccount.accessor]}</div>
                  )}
                </div>
                </>
              })}
              <div className="d-flex flex-row-reverse">
                <button
                  onClick={saveAccountConfigs}
                  className="btn border-radius-m p-v-s text-white bg-success"
                  disabled={saveConfigLoading}
                >
                  <span className="svg-icon svg-baseline mr-2 svg-white">
                    {saveConfigLoading ? (
                      <div class="spinner-border text-white spinner-border-sm" role="status"></div>
                    ) : (
                      <FloppyDiskIcon />
                    )}
                  </span>
                  <span>Save</span>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Data Sync</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            {config.data.syncEnabled ? syncEnabledModal() : syncDisabledModal()}
          </div>
        </>
      )}
      {brightPearlConfig.data.syncEnabled &&
        deskeraInfo.accountConnected &&
        accounts.data.length > 0 && (
          <>
            <div className="border-bottom w-600 mb-2 text-muted">
              <h5>Transaction Sync</h5>
            </div>
            <div className="card w-600 mb-4 dk-card dk-card-shadow">
              <div className="card-body">
                {!accountsConfigured && (
                  <Alert type="primary">
                    Accounts must be configured before BrightPearl transactions can be synced with
                    Deskera Books.
                  </Alert>
                )}
                {
                  <>
                    <div className="form-group">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="transaction_sync_toggle"
                          defaultChecked={twoWaySync}
                          onChange={() => {
                            if (twoWaySync) {
                              dispatch(
                                setConfig({
                                  ...brightPearlConfig.data,
                                  twoWaySync: !twoWaySync,
                                })
                              );
                              setTwoWaySync(!twoWaySync);
                            } else {
                              dispatch(
                                setConfig({
                                  ...brightPearlConfig.data,
                                  twoWaySync: !twoWaySync,
                                  syncInventoriesDeskeraToSource: false,
                                  syncInventoriesSourceToDeskera: false,
                                })
                              );
                              setTwoWaySync(!twoWaySync);
                              setSyncInventoriesDeskeraToSource(false);
                              setSyncInventoriesSourceToDeskera(false);
                            }
                          }}
                        />
                        <label class="form-check-label text-muted" for="transaction_sync_toggle">
                          Enable two-way sync
                        </label>
                      </div>
                    </div>
                  </>
                }
                {twoWaySync && (
                  <>
                    <div className="form-group">
                      <div className="text-muted mb-2" aria="label">
                        <b>Two-Way Sync Settings</b>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox3"
                          defaultChecked={syncInventoriesDeskeraToSource}
                          onChange={() => {
                            dispatch(
                              setConfig({
                                ...brightPearlConfig.data,
                                syncInventoriesDeskeraToSource: !syncInventoriesDeskeraToSource,
                              })
                            );
                            setSyncInventoriesDeskeraToSource(!syncInventoriesDeskeraToSource);
                          }}
                        />
                        <label class="form-check-label text-muted" for="inlineCheckbox3">
                          Enable Stock IN/OUT Sync from Deskera to BrightPearl
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox4"
                          defaultChecked={syncInventoriesSourceToDeskera}
                          onChange={() => {
                            dispatch(
                              setConfig({
                                ...brightPearlConfig.data,
                                syncInventoriesSourceToDeskera: !syncInventoriesSourceToDeskera,
                              })
                            );
                            setSyncInventoriesSourceToDeskera(!syncInventoriesSourceToDeskera);
                          }}
                        />
                        <label class="form-check-label text-muted" for="inlineCheckbox4">
                          Enable Stock IN/OUT Sync from BrightPearl to Deskera
                        </label>
                      </div>
                    </div>
                  </>
                )}
                {
                  <div className="d-flex flex-row-reverse">
                    <button
                      onClick={(e) => saveTransactionSync(e)}
                      disabled={saveTransactionSyncLoading}
                      className="btn border-radius-m p-v-s text-white bg-success"
                      // style={{ marginRight: "20px" }}
                    >
                      <span className="svg-icon svg-baseline mr-2 svg-white">
                        {saveTransactionSyncLoading ? (
                          <div
                            class="spinner-border text-white spinner-border-sm"
                            role="status"
                          ></div>
                        ) : (
                          <FloppyDiskIcon />
                        )}
                      </span>
                      <span>Save</span>
                    </button>
                  </div>
                }
              </div>
            </div>
          </>
        )}
      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Warehouse Mappings</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            <div className="card-body">
              <div className="form-group">
                {Object.keys(warehouseMappings.data).map((warehouseCode, index) => (
                  <div key={index} className="">
                    <div className="d-flex justify-content-between">
                      <div className="text-muted mb-2">
                        <p
                          className="m-0"
                          style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                            color: "#4B5563",
                          }}
                        >
                          {warehouseCode}
                        </p>
                      </div>
                    </div>
                    {Array.isArray(warehouseMappings.data[warehouseCode]) &&
                      warehouseMappings.data[warehouseCode].map((brightPearlWarehouses) => (
                        <React.Fragment key={brightPearlWarehouses.id}>
                          <div className="mt-1 d-flex justify-content-between">
                            <div className="text-muted mb-2">
                              <p
                                className="m-0 font-extrabold text-2xl text-gray-700 underline"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "0.85rem",
                                }}
                              >
                                {brightPearlWarehouses?.name}
                              </p>
                            </div>
                          </div>
                          <div className="mt-0">
                            <Select
                              placeholder="Select a Warehouse"
                              theme={customSelectTheme}
                              options={defaultDeskeraWarehouses.filter(
                                (wh) => wh.value !== "NO_SPECIFIC_WH"
                              )}
                              isSearchable={true}
                              menuPlacement="auto"
                              onChange={(e) => {
                                const updatedWarehouseMappings = updateWarehouseCodeAndName(
                                  warehouseMappings?.data,
                                  warehouseCode,
                                  brightPearlWarehouses.id,
                                  e.value,
                                  e.label
                                );
                                dispatch(setWarehouseMappings(updatedWarehouseMappings));
                              }}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              }}
                              value={deskeraWarehouses.filter(
                                (obj) =>
                                  obj.value ===
                                  warehouseMappings.data[warehouseCode].find(
                                    (w) => w.id === brightPearlWarehouses.id
                                  ).deskeraWarehouseCode
                              )}
                            />
                          </div>
                        </React.Fragment>
                      ))}
                  </div>
                ))}
                <div className="d-flex flex-row-reverse mt-2">
                  <button
                    className="btn dk-btn font-weight-bold text-success border border-success px-4"
                    onClick={async () => {
                      try {
                        setLoadingUpdateWarehouseMapping(true);

                        const warehouseMapping = warehouseMappings.data;
                        await Promise.all(
                          Object.keys(warehouseMapping).map((warehouseCode) =>
                            dispatch(updateWarehouseMappings(warehouseCode, warehouseMapping))
                          )
                        );

                        dispatch(
                          addToast({
                            type: "success",
                            title: "Success",
                            message: "Warehouse mappings updated successfully!",
                          })
                        );
                      } catch (e) {
                        console.error("Error updating warehouse mappings:", e);
                        dispatch(
                          addToast({
                            type: "danger",
                            title: "Failure",
                            message: "Failed to update warehouse mappings. Please try again.",
                          })
                        );
                      } finally {
                        setLoadingUpdateWarehouseMapping(false);
                      }
                    }}
                    disabled={loadingUpdateWarehouseMapping}
                  >
                    <span className="svg-icon svg-baseline mr-2 svg-success">
                      {loadingUpdateWarehouseMapping ? (
                        <div
                          className="spinner-border text-success spinner-border-sm"
                          role="status"
                        ></div>
                      ) : (
                        <FloppyDiskIcon />
                      )}
                    </span>
                    <span>Save</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Config;
