import { useSelector } from "react-redux";

export const useBrightpearlConfigValue = (configKey) => {
  return useSelector((state) => {
    const brightpearlConfig = state.brightpearl?.config?.data;
    return brightpearlConfig ? brightpearlConfig[configKey] : undefined;
  });
};
// get all configs

export const useBrightpearlConfig = () => {
  return useSelector((state) => state.brightpearl?.config?.data);
};
