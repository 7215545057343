import { DKButton, DKListPicker } from "deskera-ui-library";
import { useState } from "react";
import { updateBrightPearlForCustomers } from "src/api";
export const ContactUpdateSelection = ({ setApiStatuses, apiStatuses, callbackFn }) => {
  const options = ["Yesterday", "Last 5 Days", "Last 7 Days", "Last 15 Days"];
  const indexValueMap = {
    0: 1,
    1: 5,
    2: 7,
    3: 15,
  };

  const [showExportOptions, setShowExportOptions] = useState(false);

  const handleUpdateContacts = async (value) => {
    try {
      setApiStatuses((prev) => ({ ...prev, brightpearlUpdateContacts: true }));
      const payload = {
        updateLastNDays: value,
      };
      await updateBrightPearlForCustomers(payload);
    } catch (err) {
      console.error("Error updating Brightpearl contacts:", err);
    } finally {
      setApiStatuses((prev) => ({ ...prev, brightpearlUpdateContacts: false }));
      callbackFn();
    }
  };

  const showExportOptionsList = () => {
    return (
      <DKListPicker
      className="position-absolute shadow-m z-10 w-full"
      style={{
        top: "3rem",
        zIndex: "100",
        minWidth: "5rem",
        width: "100%",        
      }}
      data={options}
      onSelect={(index, value) => {
        const optionValue = indexValueMap[index] ?? 1;
        handleUpdateContacts(optionValue);
        setShowExportOptions(false);
      }}
      onClose={() => {
        setShowExportOptions(false)
      }}
    />
    );
  };
  return (
    <div className="position-relative DKLiskPicker2">
      <DKButton
        className="bg-success border-l text-white mx-2"
        disabled={apiStatuses.brightpearlUpdateContacts}
        onClick={() => {
          setShowExportOptions(true);
        }}
        style={{
          border: "1px",
        }}
        icon={null}
        title={
          <>
            <span className="svg-icon svg-baseline svg-success">
              {apiStatuses.brightpearlUpdateContacts ? (
                <div
                  className="spinner-border text-light spinner-border-sm mx-2"
                  role="status"
                ></div>
              ) : null}
            </span>
            {"Update Contact(s)"}
          </>
        }
      />
      {showExportOptions && showExportOptionsList()}
    </div>
  );
};
