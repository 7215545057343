import Link from "@material-ui/core/Link";
import getSymbolFromCurrency from "currency-symbol-map";
import { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { migrateBrightPearlForOrders, migrateBrightPearlForPurchaseOrders } from "src/api";
import Alert from "src/components/Alert";
import PopUp from "src/components/PopUp";
import ServerPaginationTable from "src/components/ServerPaginationTable";
import SyncStatus from "src/components/SyncStatus";
import {
  loadConfig,
  loadAccounts,
  loadJob,
  loadPurchaseOrders,
} from "src/redux/actions/brightpearl";
import { BRIGHTPEARL_CONFIG } from "src/redux/reducers/constants";
import { useBrightpearlConfig, useBrightpearlConfigValue } from "src/redux/reducers/hooks";
import { CONSTANTS } from "src/utils/Constants";
import { getQueryParams, Utility } from "src/utils/Utility";

export default function PurchaseOrders() {
  const data12 = useSelector((state) => state);
  console.log(data12);
  const orders = useSelector((state) => state.brightpearl.purchaseOrders);
  const dispatch = useDispatch();
  const job = useSelector((state) => state.brightpearl.job);
  const tenantInfo = useSelector((state) => state.auth.user);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const accounts = useSelector((state) => state.brightpearl.accounts);
  const isPoSyncEnabled = useBrightpearlConfigValue(BRIGHTPEARL_CONFIG.IS_PO_ENABLED);
  const brightpearlConfig = useBrightpearlConfig();
  useEffect(() => {
    dispatch(loadConfig());
    if (accounts?.data?.length === 0) {
      dispatch(loadAccounts());
    }
    dispatch(loadJob());
  }, []);

  useEffect(() => {
    dispatch(loadJob());
  }, job);

  const data = useMemo(() => orders?.data ?? [], [orders]);

  const columns = useMemo(
    () => [
      {
        Header: "Deskera Number",
        accessor: "deskeraDocumentSequenceCode",
      },
      {
        Header: "BrightPearl PO Number",
        accessor: "brightpearlId",
      },
      {
        Header: "Order Date",
        accessor: "orderDate",
        disableSortBy: true,
        Cell: ({ cell: { row } }) => {
          const orderDate = row.original.orderDate;
          const tenantDateFormat = tenantInfo?.dateFormat || "DD-MM-YYYY";

          const date = Utility.isNotEmpty(orderDate)
            ? Utility.getFormattedDateString(
                orderDate,
                Utility.DATE_FORMATS["YYYY-MM-DD"],
                Utility.DATE_FORMATS[tenantDateFormat] || Utility.DATE_FORMATS["DD-MM-YYYY"]
              )
            : "";

          return (
            <>
              <span>{date}</span>
            </>
          );
        },
      },
      {
        Header: "Supplier Name",
        accessor: "customerName",
      },
      {
        Header: "Total Amount",
        accessor: "totalAmount",
        Cell: ({ cell: { row } }) => (
          <span>
            {row.original.totalAmount && getSymbolFromCurrency(row.original.currency)}{" "}
            {row.original.totalAmount}
          </span>
        ),
        className: "text-right",
      },

      {
        Header: "Sync Status",
        accessor: "syncStatus",
        Cell: ({ cell: { row } }) => {
          return (
            <SyncStatus
              status={row.original.syncStatus}
              reason={row.original.reason}
              id={row.original[BRIGHTPEARL_CONFIG.ROW_ID]}
            />
          );
        },
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: "Brightpearl Goods Receipt Status",
        accessor: "goodsReceiptStatusBp",
        Cell: ({ cell: { row } }) => {
          return (
            <SyncStatus
              status={row.original.goodsReceiptStatusBp}
              reason={""}
              id={row.original[BRIGHTPEARL_CONFIG.ROW_ID]}
            />
          );
        },
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: "Deskera Goods Receipt Status",
        accessor: "goodsReceiptStatusDeskera",
        Cell: ({ cell: { row } }) => {
          return (
            <SyncStatus
              status={row.original.goodsReceiptStatusDeskera}
              reason={""}
              id={row.original[BRIGHTPEARL_CONFIG.ROW_ID]}
            />
          );
        },
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: "Sync Remarks",
        accessor: "reason",
        disableSortBy: true,
        Cell: ({ cell: { row } }) => {
          const text = row?.original?.syncRemarks;
          const displayText = text?.length > 50 ? text.slice(0, 50) : text;
          return (
            <>
              {!(row.original.syncStatus === "SUCCESSFUL") && (
                <PopUp text={text} triggerText={displayText} />
              )}
            </>
          );
        },
      },
    ],
    [accounts]
  );

  useEffect(() => {
    setLoading(false);
    if (data && data.totalPages) {
      setPageCount(data.totalPages);
    }
  }, [data]);

  const refreshData = ({
    pageSize,
    pageIndex,
    status,
    sortBy,
    startDate,
    endDate,
    search,
    query,
  }) => {
    const queryParams = getQueryParams(query);
    dispatch(
      loadPurchaseOrders(
        pageSize,
        pageIndex,
        status,
        sortBy,
        startDate,
        endDate,
        search ?? "",
        queryParams
      )
    );
  };

  useEffect(() => {
    refreshData({});
  }, [isPoSyncEnabled]);

  return (
    <div className="p-4">
      <h3 className="mb-3">Purchase Orders</h3>
      {(!brightpearlConfig || !brightpearlConfig.syncEnabled) && (
        <Alert type="warning">
          Your synced orders will appear here. Please complete{" "}
          <Link to="/app/brightpearl" className="text-alert-warning">
            <u>Setup</u>
          </Link>{" "}
          before continuing.
        </Alert>
      )}
      {!isPoSyncEnabled && (
        <>
          <Alert type="warning">Please Enable Purchase Order Sync to Brightpearl.</Alert>
        </>
      )}
      <ServerPaginationTable
        data={data.content ? data.content : []}
        columns={columns}
        migrate={migrateBrightPearlForPurchaseOrders}
        cart={CONSTANTS.STORE.BRIGHTPEARL}
        refresh={refreshData}
        tab={CONSTANTS.MODULE.PURCHASE_ORDER}
        pageCount={pageCount}
        loading={loading}
        rowId={BRIGHTPEARL_CONFIG.ROW_ID}
      />
    </div>
  );
}
